import {Navbar, Nav, Container, Row, Col, NavDropdown} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../assets/logo.png' 
import * as Routes from '../Constants/routes';
import { useRef, useEffect, useState } from 'react';
import Offer from './Offer';
import { useLocation } from 'react-router-dom';
import SearchBox from './SearchBox';
import {FaSearch, FaPhoneAlt} from 'react-icons/fa'
import {CiMail} from 'react-icons/ci'
import fbLogo from '../assets/fb-logo.png'
import instaLogo from '../assets/insta-logo.png'
import WhatsappLogo from '../assets/whatsappLogo.png'

const Header = () => {

    const navbar = useRef(null);
    const { pathname } = useLocation();
    const [expanded, setExpanded] = useState(false)

    const overrideToggle = () => {
        setExpanded(prevExpanded => !prevExpanded)
    }

    const scrollHandler = () => {
      if (navbar.current && window.screen.width > 480) {
        if (window.pageYOffset >= 70) {
          navbar.current.classList.add('is-nav-scrolled');
        } else {
          navbar.current.classList.remove('is-nav-scrolled');
        }
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    const isOfferToShow = () => {
      return false
      // pathname === Routes.HOME || pathname === Routes.NEW_PRODUCTS || pathname === Routes.OUR_PRODUCTS
    }

    const isSearchShow = () => {
      return !window.location.href.includes(Routes.SEARCH)
    }

    const submitHandler = (e) => {
      e.preventDefault()
      const recipient = "Pureiumenterprise@gmail.com"
      const subject = `Query: ${'Query from pureium'}`
      const body = `    `
      const url = `mailto:${recipient}?cc=&subject=${subject}&body=${body}`
      window.open(url)
  }

  const openMobile = (e) => {
    window.open(`tel:${"+91 911-611-8284"}`)
  }

  const openWhatsapp = (e) => {
    window.open('https://api.whatsapp.com/send?phone=919116118284')
  }

  const openFB = (e) => {
    window.open('https://www.facebook.com/people/Pureium-Store/100091680653179/?mibextid=LQQJ4d')
  }

  const openInsta = (e) => {
    window.open('https://www.instagram.com/pureium.co?igsh=NTc4MTIwNjQ2YQ==')
  }
  
  return (
    <>
    <header  ref={navbar}   style={{backgroundColor: '#FFF212'}}>
      {isOfferToShow() && (<Offer className='is-nav-scrolled' />)}
        <Navbar bg='#f9f9f9' variant='light' expand='lg' collapseOnSelect expanded = {expanded} onToggle={overrideToggle}>
    <Container>
    <Navbar.Toggle aria-controls='basic-navbar-nav' ></Navbar.Toggle>
        <Navbar.Collapse >

        <Nav>

        <LinkContainer to={Routes.SEARCH}>
        <Nav.Link className='.navigation-menu-link' style={{width:'100%'}}>Search <FaSearch className='ms-auto'/></Nav.Link>
        </LinkContainer>

        <LinkContainer to={Routes.HOME}>
        <Nav.Link className='.navigation-menu-link'>Home</Nav.Link>
        </LinkContainer>
      <NavDropdown title="Contact Us">

      <LinkContainer to={''} onClick={openMobile}>
        <Nav.Link className='.navigation-menu-link'><FaPhoneAlt/> +91 911-611-8284</Nav.Link>
        </LinkContainer>

        <LinkContainer to={''} onClick={submitHandler}>
        <Nav.Link className='.navigation-menu-link'><CiMail/> Pureiumenterprise@gmail.com</Nav.Link>
        </LinkContainer>
      </NavDropdown>
<hr/>
      <Nav.Link>
      <Container className="page-footer">
        <Row>
                
                
                <Col>
                <LinkContainer to={''} onClick={openWhatsapp}>
                <Nav.Link className='.navigation-menu-link'><img src={WhatsappLogo} alt="insta" height={30} width={30}></img></Nav.Link>
                </LinkContainer>
                </Col>
                <Col>
                <LinkContainer to={''} onClick={openFB}>
                <Nav.Link className='.navigation-menu-link'><img src={fbLogo} alt="fb" height={30} width={30}></img></Nav.Link>
                </LinkContainer>
                </Col>
                <Col>
                <LinkContainer to={''} onClick={openInsta}>
                <Nav.Link className='.navigation-menu-link'><img src={instaLogo} alt="insta" height={30} width={30}></img></Nav.Link>
                </LinkContainer>
                </Col>
        </Row>
            </Container>
      </Nav.Link>
        {/* <LinkContainer to={Routes.CONTACT_US}>
        <Nav.Link className='.navigation-menu-link'>Contact Us</Nav.Link>
        </LinkContainer> */}
            </Nav>
            </Navbar.Collapse>   
        {!expanded && (<LinkContainer to={Routes.HOME} className='fluid'>
        <Navbar.Brand>
            <Row className='row-center-align'>
                <Col>
                <img src={logo} alt='Pureium'  width='100px'></img>
                </Col>
            </Row>
        </Navbar.Brand>
        </LinkContainer>)}
        
        {isSearchShow() && !expanded && (<SearchBox />)}
        {!isSearchShow() && (<h1></h1>)}
        

        {/* <LinkContainer to={Routes.HOME}>
        <Nav.Link className='ms-auto'>Purium</Nav.Link>
        </LinkContainer> */}
        
        
            
     </Container>
        </Navbar>
    </header>
    </>
      )
}

export default Header
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { searchProduct } from '../redux/slices/productSlice';
import { useSelector } from 'react-redux';
import ProductGrid from '../Components/ProductGrid';
import { Button, Form } from 'react-bootstrap';
import * as Routes from '../Constants/routes'
import {FaSearch} from 'react-icons/fa'

const SearchScreen = () => {

    const {keyword: searchKeywords} = useParams();
    const products = useSelector(state => searchProduct(state, searchKeywords))
    
    const navigate = useNavigate()
    const [keyword, setKeyword] = useState(searchKeywords || '');

    const submitHandler = (e) => {
        e.preventDefault();

        if(keyword.trim()) {
            navigate(Routes.SEARCH_PRODUCTS.replace(':keyword', keyword));
        } else {
            navigate(Routes.SEARCH);
        }
    }

  return (
    <>
        <h1> Search Results</h1>
        <Form onSubmit={submitHandler} className="d-flex searchText">
        <Form.Control
            type="text"
            name="query"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            placeholder="Search Products..."
            className="mr-sm-2 ml-sm-5 round"
        ></Form.Control>
        <Button type="submit" variant="primary" className="p-2 mx-2 corner"><FaSearch size={20}/></Button>
    </Form>
        {
         products.length > 0 ? 
            <ProductGrid products={products}></ProductGrid>
         : <h2>No product found</h2>}
    </>
  )
}

export default SearchScreen
import { Col, Row } from 'react-bootstrap'
import Product from './Product'
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import * as Routes from '../Constants/routes'

const CategoryGrid = ({products}) => {
  return (
    <Row className='row-horz-align'>
    {products.map((product) => (
        <Col key={product._id} sm={6} xs={6} md={4} lg={4} xl= {3}>
            <Category  category={product}/>
        </Col>
    ))}
  </Row>
)
}

export default CategoryGrid

const Category = ({category}) => {
  return (
    <Card className="my-3 p-3 rounded">
        <Link to={Routes.CATEGORY_DETAIL.replace(':id', category._id)} className="clip-bound">
            <Card.Img src={category.image} variant="top" className="image-hover-scale"></Card.Img>
        </Link>
        <Card.Body>
            <Link to={Routes.CATEGORY_DETAIL.replace(':id', category._id)}>
                <Card.Title as="div">
                    <strong>{category.name}</strong>
                </Card.Title>
            </Link>
        </Card.Body>
    </Card>
  )
}
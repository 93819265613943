import { Col, Row } from 'react-bootstrap'
import Product from './Product'

const ProductGrid = ({products}) => {
  return (
    <Row className='row-horz-align'>
    {products.map((product) => (
        <Col key={product._id} sm={6} xs={6} md={4} lg={4} xl= {3}>
            <Product  product={product}/>
        </Col>
    ))}
  </Row>
)
}

export default ProductGrid
export const category = {
  cotton_wicks: {
    name: "Cotton Wicks",
    image: '/images/jyot.jpeg',
    _id: 1
  },
  camphor: {
    name: "Camphor",
    image: '/images/product/camphor_10gm.jpg',
    _id: 2
  }, 
  kalawa:{
    name: "Kalawa",
    image: '/images/kalawa.jpeg',
    _id: 3
  },
  chandan_powder:{
    name: "Chandan Powder",
    image: '/images/product/chandan_red.jpg',
    _id: 4
  },
  chandan_tika:{
    name: "Chandan Tika",
    image: '/images/product/chandan_yellow.png',
    _id: 5
  },
  guggul:{
    name: "Guggul",
    image: '/images/gugal.jpeg',
    _id: 6
  },
  loban:{
    name: "Loban",
    image: '/images/loban.jpeg',
    _id: 7
  },
  kumkum_roli:{
    name: "Kumkum Roli",
    image: '/images/product/roli.jpg',
    _id: 8
  },
  sindoor:{
    name: "Sindoor",
    image: '/images/product/sindoor.jpg',
    _id: 9
  },
  festive_gift:{
    name: "Festive Gift",
    image: '/images/shringar.jpeg',
    _id: 10
  },
  insence_sticks:{
    name: "Insence Sticks",
    image: '/images/jyot.jpeg',
    _id: 11
  },
  dhoop_cones:{
    name: "Dhoop Cones",
    image: '/images/jyot.jpeg',
    _id: 12
},
  sambrani_cups:{
    name: "Sambrani Cups",
    image: '/images/shringar.jpeg',
    _id: 13
  },
}

const products = [
  {
    _id: '1',
    name: 'Cotton Wicks 10g',
    image: '/images/Cotton-Wicks-10g.jpeg',
    price: "₹ 20 (Price per unit)",
    categoryType: category.cotton_wicks
  },
  {
    _id: '2',
    name: 'Cotton Wicks 70g',
    image: '/images/Cotton-Wicks-70g.jpeg',
    price: "₹ 80 (Price per unit)",
    categoryType: category.cotton_wicks
  },
  {
    _id: '3',
    name: 'Camphor Tablet 10g',
    image: '/images/product/camphor_10gm.jpg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.camphor
  },
  {
    _id: '4',
    name: 'Camphor Tablet 30g',
    image: '/images/Camphor-Tablet-30g.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.camphor
  },
  {
    _id: '5',
    name: 'Camphor Tablet 60g',
    image: '/images/Camphor-Tablet-60g.jpeg',
    price: "₹ 150 (Price per unit)",
    categoryType: category.camphor
  },
  {
    _id: '6',
    name: 'Camphor Slab 50g',
    image: '/images/product/camphor_50gm.jpg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.camphor
  },
  {
    _id: '7',
    name: 'Bhimseni Camphor 60g',
    image: '/images/product/camphor_100gm.jpg',
    price: "₹ 150 (Price per unit)",
    categoryType: category.camphor
  },
  {
    _id: '8',
    name: 'Kalawa-20g',
    image: '/images/Kalawa 20g.jpeg',
    price: "₹ 30 (Price per unit)",
    categoryType: category.kalawa
  },
  {
    _id: '9',
    name: 'Kalawa-40g',
    image: '/images/Kalawa 40g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.kalawa
  },{
    _id: '10',
    name: 'Kalawa-80g',
    image: '/images/Kalawa 80g.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.kalawa
  },{
    _id: '11',
    name: 'Satnala',
    image: '/images/Satnala.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.kalawa
  },
  {
    _id: '12',
    name: 'Chandan Red 30g',
    image: '/images/product/chandan_red.jpg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_powder
  },
  {
    _id: '13',
    name: 'Chandan Yellow 30g',
    image: '/images/product/chandan_yellow.jpg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_powder
  },{
    _id: '14',
    name: 'Chandan Kesar 30g',
    image: '/images/product/chandan_orange.jpg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_powder
  },{
    _id: '15',
    name: 'Chandan White 30g',
    image: '/images/product/chandan_white.jpg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_powder
  },
  {
    _id: '16',
    name: 'Natural Chandan Tika 30g',
    image: '/images/Natural-Chandan-Tika-30g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_tika
  },
  {
    _id: '17',
    name: 'Kesar Chandan Tika 30g',
    image: '/images/Kesar-Chandan-Tika-30g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.chandan_tika
  },
  {
    _id: '18',
    name: 'Guggul 40g',
    image: '/images/Guggul-40g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.guggul
  },
  {
    _id: '19',
    name: 'Guggul 80g',
    image: '/images/Guggul-80g.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.guggul
  },
  {
    _id: '20',
    name: 'Loban 40g',
    image: '/images/Loban-40g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.guggul
  },
  {
    _id: '21',
    name: 'Loban 80g',
    image: '/images/Loban-80g.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.guggul
  },
  {
    _id: '22',
    name: 'Roli 15g',
    image: '/images/Roli-15g.jpeg',
    price: "₹ 20 (Price per unit)",
    categoryType: category.kumkum_roli
  },
  {
    _id: '23',
    name: 'Roli 30g',
    image: '/images/Roli-30g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.kumkum_roli
  },
  {
    _id: '24',
    name: 'Sindoor 10g',
    image: '/images/Sindoor-10g.jpeg',
    price: "₹ 20 (Price per unit)",
    categoryType: category.sindoor
  },
  {
    _id: '25',
    name: 'Sindoor 25g',
    image: '/images/Sindoor-25g.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.sindoor
  },
  {
    _id: '26',
    name: 'Diwali Gift S',
    image: '/images/Diwali Gift-S.jpeg',
    price: "₹ 100 (Price per unit)",
    categoryType: category.festive_gift
  },
  {
    _id: '27',
    name: 'Diwali Gift M',
    image: '/images/Diwali Gift-M.jpeg',
    price: "₹ 300 (Price per unit)",
    categoryType: category.festive_gift
  },
  {
    _id: '28',
    name: 'Diwali Gift B',
    image: '/images/Diwali Gift-B.jpeg',
    price: "₹ 500 (Price per unit)",
    categoryType: category.festive_gift
  },
  {
    _id: '29',
    name: 'Insence Rose',
    image: '/images/Insence-Rose.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.insence_sticks
  },
  {
    _id: '30',
    name: 'Insence Mogra',
    image: '/images/Insence-Mogra.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.insence_sticks
  },
  {
    _id: '31',
    name: 'Insence Guggul',
    image: '/images/Insence-Guggul.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.insence_sticks
  },
  {
    _id: '32',
    name: 'Insence Chandan',
    image: '/images/Insence-Chandan.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.insence_sticks
  },
  {
    _id: '33',
    name: 'Dhoop Rose',
    image: '/images/Dhoop-Rose.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '34',
    name: 'Dhoop Mogra',
    image: '/images/Dhoop-Mogra.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '35',
    name: 'Dhoop Guggul',
    image: '/images/Dhoop-Guggul.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '36',
    name: 'Dhoop Chandan',
    image: '/images/Dhoop-Chandan.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '37',
    name: 'Guggul Sambrani Cups',
    image: '/images/Guggul-Sambrani-Cups.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '38',
    name: 'Loban Sambrani Cups',
    image: '/images/Loban-Sambrani-Cups.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },
  {
    _id: '39',
    name: 'Camphor Sambrani Cups',
    image: '/images/Camphor-Sambrani-Cups.jpeg',
    price: "₹ 50 (Price per unit)",
    categoryType: category.dhoop_cones
  },  
]

export default products


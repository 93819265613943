import React from 'react'
import ProductListScreen from './ProductListScreen'
import { useSelector} from 'react-redux';
import { getProducts } from '../redux/slices/productSlice';
import Banner from '../Components/Banner';
import bannerImage from '../assets/logo.png'; 
import ProductCarousel from '../Components/ProductCarousel';

const OurProductsScreen = () => {
  const products = useSelector(getProducts);

  return (
    <ProductListScreen
        products={products}
        header={<ProductCarousel products={products}/>}
    ></ProductListScreen>
  )
}

export default OurProductsScreen
import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOfferCanceled: window.localStorage.getItem('isOfferClosed') ? JSON.parse(localStorage.getItem('isOfferClosed')) : false };

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        offerClosed(state, action) {
            console.log("state update called");
            window.localStorage.setItem("isOfferClosed", JSON.stringify(true));
            state.isOfferCanceled = true;
        }
    }
})

export const isOfferAlreadyClosed = state => state.app.isOfferCanceled;

export const {offerClosed} = appSlice.actions;
export default appSlice.reducer;
import {getCategory, homeData} from '../redux/slices/productSlice'
import { useSelector } from "react-redux";
import ProductCarousel from "../Components/ProductCarousel";
import ProductGrid from "../Components/ProductGrid";
import ContactUsScreen from "./ContactUsScreen";
import CategoryGrid from '../Components/Category';
import slide1 from '../assets/slide1.jpg' 

const HomeScreens = () => {
  const data = useSelector(homeData);
  
  return (
    <>
    <ProductCarousel products={[{image: slide1},{image: slide1},{image: slide1}]}/>

    <CategoryGrid products={Object.keys(data).map((key) => { 
      const category = data[key]
      return {
      name: category.name,
      image: category.image,
      _id: category._id
    }
    })}></CategoryGrid>
    <br></br>
    <br></br>
    <br></br>
    <ContactUsScreen />
    </>
  )
}

export default HomeScreens
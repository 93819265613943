import React from 'react'
import { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import FormContainer from '../Components/FormContainer'
import WhatsappLogo from '../assets/whatsappLogo.png'
import {FaPhoneAlt} from 'react-icons/fa'


const ContactUsScreen = () => {

    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')    
    const [title, setTitle] = useState('')
    const [query, setQuery] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        const recipient = "Pureiumenterprise@gmail.com"
        const subject = `Query: ${title}`
        const body = `from mobile - ${mobile}, %0A%0A name - ${name}  %0A%0A query -  ${query}`
        const url = `mailto:${recipient}?cc=&subject=${subject}&body=${body}`
        window.open(url)
    }

  return (
        <FormContainer>
            <h1> Contact Us</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='name' className='my-3'>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                        type='name'
                        placeholder='full name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='mobile' className='my-3'>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                        type='mobile'
                        placeholder='Enter Mobile'
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='title' className='my-3'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type='title'
                        placeholder='Enter Query type'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='query' className='my-3'>
                    <Form.Label>Query</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='type your query'
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        
                    ></Form.Control>
                </Form.Group>

                    <Button type='submit' variant='primary' className='my-3'>Submit</Button>

            </Form>

            <Row>
                <Col>
                Connect on <a href='https://api.whatsapp.com/send?phone=919116118284"'><img src={WhatsappLogo} height={30} width={30}/> WhatsApp</a>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col>
                For more query connect <FaPhoneAlt/> +91 911-611-8284
                </Col>
            </Row>
        </FormContainer>

  )
}

export default ContactUsScreen
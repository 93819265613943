import { Carousel, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { getProducts } from "../redux/slices/productSlice"
import { useSelector } from "react-redux"


const ProductCarousel = ({products}) => {
    // const products = useSelector(getProducts)
  return (
    <Carousel pause='hover' className='bg-primary mb-4'>
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          <Link to={""}>
            <Image src={product.image} alt={"image suppose to be there"}  style={{width: '100%', aspectRatio:'1.78/1 '}}/>
            <Carousel.Caption className='carousel-caption'>
              {/* <h2 className='text-white text-right'>
                {product.name} (₹{product.price})
              </h2> */}
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default ProductCarousel


import React from 'react'
import ProductListScreen from './ProductListScreen'
import { useSelector} from 'react-redux';
import { getCategoryProduct, getCategory } from '../redux/slices/productSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as Routes from '../Constants/routes';

const CategoryScreen = () => {
    const { id: categoryId } = useParams() 
    const naviagte = useNavigate()
    const product = useSelector(state => getCategoryProduct(state, categoryId))
    const category = useSelector(state => {
        const key = Object.keys(state.product.category).find(key => state.product.category[key]._id == categoryId)
        return state.product.category[key]
    })
  return (
    <>
    <h3>
        <a href="/">Home</a>
        &nbsp; &gt;  	&nbsp;
         {category.name}
         </h3>
    <ProductListScreen
        products={product}
    ></ProductListScreen>
    </>
  )
}

export default CategoryScreen

import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import * as Routes from '../Constants/routes'

const Product = ({product}) => {
  return (
    <Card className="my-3 p-3 rounded">
        <Link to={Routes.PRODUCT_DETAIL.replace(':id', product._id)} className="clip-bound">
            <Card.Img src={product.categoryType.image} variant="top" className="image-hover-scale"></Card.Img>
        </Link>
        <Card.Body>
            <Link to={`/product/${product._id}`}>
                <Card.Title as="div">
                    <strong>{product.name}</strong>
                </Card.Title>
            </Link>
            {product.price != '' && <Card.Text as="h3">
            {product.price}
            </Card.Text>}
        </Card.Body>
    </Card>
  )
}

export default Product
import { Outlet } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import { Container } from 'react-bootstrap'

const App = () => {

  return (
    <>
    <Header></Header>
    <main>
      <Container>
        <Outlet />
      </Container>
    </main>
    <Footer />
    </>
  )
}

export default App
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, 
createRoutesFromElements,
Route,
RouterProvider
 } from 'react-router-dom';
import './assets/styles/bootstrap.custom.css'
import './assets/styles/index.css'
import './assets/styles/main.scss'
import './assets/styles/templateScss/style.scss'
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Routes from './Constants/routes';
import HomeScreens from './Screens/HomeScreens';
import ProductScreen from './Screens/ProductDetailScreen';
import ContactUsScreen from './Screens/ContactUsScreen';
import NewProductsScreen from './Screens/NewProductsScreen';
import OurProductsScreen from './Screens/OurProductsScreen';
import SearchScreen from './Screens/SearchScreen';
import CategoryScreen from './Screens/CategoryScreen';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path={Routes.HOME} element={<HomeScreens></HomeScreens>}></Route>
      <Route path={Routes.SEARCH_PRODUCTS} element={<SearchScreen></SearchScreen>}> </Route>
      <Route path={Routes.SEARCH} element={<SearchScreen></SearchScreen>}> </Route>
      <Route path={Routes.PRODUCT_DETAIL} element={<ProductScreen></ProductScreen>}> </Route>
      {/* <Route path={Routes.CONTACT_US} element={<ContactUsScreen></ContactUsScreen>}></Route> */}
      <Route path={Routes.NEW_PRODUCTS} element={<NewProductsScreen></NewProductsScreen>}></Route>
      <Route path={Routes.OUR_PRODUCTS} element={<OurProductsScreen></OurProductsScreen>}></Route>
      <Route path={Routes.CATEGORY_DETAIL} element={<CategoryScreen></CategoryScreen>}></Route>
      </ Route>
  )
)

root.render( 
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import ProductGrid from '../Components/ProductGrid'

const ProductListScreen = ({products, header}) => {
  return (
    <>
    {header}
    <ProductGrid products={products}></ProductGrid>
    </>
  )
}

export default ProductListScreen
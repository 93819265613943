import { useParams } from "react-router-dom"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Image, ListGroup, Card, Button, ListGroupItem } from "react-bootstrap"
import { useSelector } from "react-redux"
import { getProductDetail } from "../redux/slices/productSlice"
import * as Routes from '../Constants/routes'


const ProductScreen = () => {
    const { id: productId } = useParams() 
    const product = useSelector(state => getProductDetail(state, productId))
    const naviagte = useNavigate();
    
  return (
    <>
    {/* <Row>
        <Col md={1}>
        <Button className="btn-block my-3"
                        type='button' onClick={() => naviagte(-1)}>Home</Button>
        </Col>
        <Col md={1}><Button className="btn-block my-3"
                        type='button' onClick={() => naviagte(-1)}>{product.categoryType.name}</Button></Col>
        
    </Row> */}

    <h3>
        <a href="/">Home</a>
        &nbsp; &gt;  	&nbsp;
         <a href={Routes.CATEGORY_DETAIL.replace(":id", product.categoryType._id)}>{product.categoryType.name}</a>
         &nbsp; &gt; 	&nbsp;
         {product.name}
         </h3>

    <Row>
        <Col md={5}>
            <Image src={product.categoryType.image} alt={product.name} fluid></Image>
        </Col>
        <Col md={4}>
            <ListGroup variant="flush">
                <ListGroupItem>
                    <h3>{product.name}</h3>
                </ListGroupItem>
                {/* <ListGroupItem>
                    Price: {product.price}
                </ListGroupItem> */}
                {/* <ListGroupItem>
                    Description: {product.description}
                </ListGroupItem> */}
            </ListGroup>
        </Col>
        <Col md={3}>
            <Card>
                <ListGroup variant="flush">
                    <ListGroupItem>
                        <Row>
                            <Col>Price:</Col>
                            <Col>{product.price}</Col>
                        </Row>
                    </ListGroupItem>
                    {/* <ListGroupItem>
                        <Row>
                            <Col>Status:</Col>
                            <Col>{product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}</Col>
                        </Row>
                    </ListGroupItem> */}

                    {/* <ListGroupItem>
                        <Button 
                        className="btn-block"
                        type='button'
                        disabled={product.countInStock === 0}
                        >Add To Cart</Button>
                    </ListGroupItem> */}
                </ListGroup>
            </Card>
        </Col>

    </Row>
    </>
  )
}

export default ProductScreen
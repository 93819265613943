import { configureStore } from "@reduxjs/toolkit";
import productSliceReducer from "./slices/productSlice";
import appSliceReducer from "./slices/appSlice";

const store = configureStore({
    reducer: {
        product: productSliceReducer,
        app: appSliceReducer
    },
})


export default store;
import { Container, Row, Col, Button } from "react-bootstrap"
import Whatsapplogo from '../assets/whatsappLogo.png'
import fbLogo from '../assets/fb-logo.png'
import instaLogo from '../assets/insta-logo.png'

const Footer = () => {
    const currentYear = new Date().getFullYear()
  return (
    <footer>
        <Container>
            <Row>
                <Col className="text-center py-3" >
                    <p>
                    Pureium &copy; {currentYear}
                    </p>
                    <Col className="wrapper"><a href='https://api.whatsapp.com/send?phone=917503455057"'><img src={Whatsapplogo} height={50} width={50}></img></a></Col>
                </Col>
            </Row>

    {/* <div className="socialMedias">
                <Button>My Button </Button>
            </div> */}
    
            <Container className="page-footer socialMedias">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/pureium.co?igsh=NTc4MTIwNjQ2YQ=="><img src={instaLogo} alt="insta"></img></a>           
                    </li>
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=100091680653179&mibextid=LQQJ4d"><img src={fbLogo} alt="fb"></img></a>
                    </li>
                </ul>
                {/* <Col><a href=""><img src={instaLogo} alt="insta"></img></a></Col>
                <Col><a href=""><img src={fbLogo} alt="fb"></img></a></Col>
        </Row>*/}
            </Container>
        </Container>
    </footer>
  )
}

export default Footer
import { useState } from "react"
import { Form, Button} from "react-bootstrap"
import { useParams, useNavigate } from "react-router-dom"
import * as Routes from '../Constants/routes'
import {FaSearch} from 'react-icons/fa'

const SearchBox = () => {
    const navigate = useNavigate()
    const {keyword: urlKeyword} = useParams();
    const [keyword, setKeyword] = useState(urlKeyword || '');

    const submitHandler = (e) => {
        e.preventDefault();

        // if(keyword.trim()) {
            navigate(Routes.SEARCH_PRODUCTS.replace(':keyword', ''));
        // } else {
        //     navigate(Routes.HOME);
        // }
    }

  return (
    <Form onSubmit={submitHandler} className="d-flex search">
        {/* <Form.Control
            type="text"
            name="query"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            placeholder="Search Products..."
            className="mr-sm-2 ml-sm-5 round"
        ></Form.Control> */}
        <Button type="submit" variant="primary" className="p-2 mx-2 corner"><FaSearch size={20}/></Button>
    </Form>
  )
}

export default SearchBox


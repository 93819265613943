import { createSlice } from "@reduxjs/toolkit";
import products, { category } from "../../products";

const initialState = { products: products, category: category };

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {}
})

var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

export const homeData = state => state.product.category;

export const getCategory = state => state.product.category || {};

export const getProducts = state => state.product.products || {};

export const getCategoryProduct = (state, category) => state.product.products.filter((product) => product.categoryType._id == category) || []

export const getNewProducts = state => state.product.products.filter((product) => product.categoryType ? product.categoryType === category.new : false) || []

export const getProductDetail = (state, productId) => state.product.products.find((product) => product._id === productId)

export const searchProduct = (state, searchText) => {
  if (!searchText) {
    return state.product.products
  }
    const regex = new RegExp(`${searchText}`, "i")
    return state.product.products.filter((product) => {
        let data = product.name.match(regex)
        return data != null
    }) || []
}

export default productSlice.reducer;
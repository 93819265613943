import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import {IoIosCloseCircleOutline} from 'react-icons/io'
import appSlice from '../redux/slices/appSlice'
import {offerClosed, isOfferAlreadyClosed} from '../redux/slices/appSlice'
import { useSelector, useDispatch } from 'react-redux'


const Offer = () => {

const isAlreadyClosed = useSelector(isOfferAlreadyClosed);
const dispatch = useDispatch();

const closeClicked = (e) => {
  e.preventDefault();
  dispatch(offerClosed());
}

  if (isAlreadyClosed) {
      return <></>
  } else {
    return (
      <div className='offer'>
  
          <p className='offer-p'
      >
          Welcome Offer: Enjoy an exclusive 7% discount on Your First Order! Applicable on purchase over Rs. 349 - Use "2023WOFFER"</p>
          
          <Button className='offer-close'><IoIosCloseCircleOutline size={30} onClick={(e) => closeClicked(e)}/></Button>
          
          </div>
    )
  }
}

export default Offer
import React from 'react'
import ProductListScreen from './ProductListScreen'
import { useSelector} from 'react-redux';
import { getNewProducts } from '../redux/slices/productSlice';
import Banner from '../Components/Banner';
import bannerImage from '../assets/logo.png'; 
import ProductCarousel from '../Components/ProductCarousel';

const NewProductsScreen = () => {
  const newProducts = useSelector(getNewProducts);

  return (
    <ProductListScreen
        products={newProducts}
        header={<ProductCarousel products={newProducts}/>}
    ></ProductListScreen>
  )
}

export default NewProductsScreen